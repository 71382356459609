export const useScrollTo = ({
  id,
  offset,
}: {
  id: string | HTMLElement
  offset?: number
}) => {
  let el: HTMLElement | null = null
  let elmId = id

  if (typeof elmId === 'string') {
    if (elmId.includes('#')) {
      elmId = elmId.replace('#', '')
    }

    el = document.getElementById(elmId) as HTMLElement
  } else if (typeof elmId === 'object') {
    el = elmId as HTMLElement
  }

  if (el) {
    let top = el.getBoundingClientRect().top + window.scrollY
    if (offset) {
      top = el.getBoundingClientRect().top + window.scrollY + offset
    }

    window.scrollTo({
      top,
      left: 0,
      behavior: 'smooth',
    })
  } else {
    console.error(`Element with id ${elmId} not found`)
  }
}
